import React from 'react';
import AppBar from '@material-ui/core/AppBar';

const Footer = () => {
    return (
        <AppBar style={{
                position: `fixed`,
                left: `0`,
                bottom: `0`,
                width: `100%`
            }} 
            position="static"
        >        
        <div style={{color: `black`, paddingLeft: `20px`}}>
            © {new Date().getFullYear()}, Készítette
            {` `}
            <a href="https://sandor-farkas.netlify.com/" target="blank">
                Farkas Sándor
            </a>
        </div>        
      </AppBar>
)}

export default Footer;


