import PropTypes from "prop-types"
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    background: '#ddd',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const Header = () => {
  const classes = useStyles();

  return (
    <Paper elevation={3} className={classes.root}>
      <AppBar position="static">
        <Toolbar>          
          <Typography style={{color: `black`}} variant="h6" component="h1" className={classes.title}>
            Autómentés, Gépszállítás | Szabolcs-Szatmár-Bereg megye
          </Typography>
        </Toolbar>
      </AppBar>
    </Paper>
  );
  }

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
